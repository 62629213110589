// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

//import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "prismjs/themes/prism-tomorrow.css"
//import "prismjs/themes/prism-okaidia.css"
import "katex/dist/katex.min.css"

import "./src/styles/global.css"

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `アプリを更新できます。最新の状態にするためページを再読込しますか？`
  )
  if (answer === true) {
    window.location.reload()
  }
}
